<template>
  <v-layout column v-if="Mayor != null" fill-height text-center>
    <Header
      titulo=""
      imagen="https://www.ofimania.com/img/Header/Mayores.webp"
    />
    <Separador titulo="Sucursales" />
    <v-layout wrap justify-center>
      <v-flex
        v-for="(tienda, index) in Mayor"
        :key="index"
        class="pa-3"
        xs12
        md6
      >
        <Tienda
          :id="'' + tienda.id"
          :nombre="tienda.nombre"
          :direccion="tienda.direccion"
          :horario="tienda.horario"
          :telefono="tienda.telefono"
          :correo="tienda.correo"
          :whatsapp="tienda.whatsapp"
          :maps="tienda.maps"
          :imagen="tienda.imagen"
        />
      </v-flex>
    </v-layout>
    <v-flex xs12 fill-height>
      <v-img
        width="cover"
        :height="movil ? '210px' : '80px'"
        src="https://www.ofimania.com/img/Banners/Mayor1.webp"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Header from "../components/Header.vue";
import Tienda from "../components/Tienda.vue";
import Banners from "../components/Banners.vue";
import Separador from "../components/Separador.vue";
import { mapState } from "vuex";
export default {
  metaInfo: {
    title: "Ventas al Mayor | Ofimania",
  },
  created() {
    this.$store.state.cargando = true;
    if (this.Mayor == null) {
      this.axios
        .get("https://www.ofimania.com/API/Tienda/Mayor/Get")
        .then((response) => {
          this.$store.state.Mayor = response.data;
          this.$store.state.cargando = false;
        })
        .catch((error) => {
          this.$store.state.cargando = false;
          this.$store.state.alerta = {
            estado: true,
            tipo: "error",
            titulo: "Error de Conexion",
            info: "Verifique su Conexion a Internet",
          };
        });
    } else {
      this.$store.state.cargando = false;
    }
  },
  data() {
    return {
      img: [],
    };
  },
  computed: {
    ...mapState(["movil", "Mayor"]),
  },
  components: {
    Header,
    Tienda,
    Banners,
    Separador,
  },
};
</script>

<style></style>
